<template>
  <div>

    <!-- 3D   greenball  blueball-->
    <div class="res-open-content binggo lgklsf">
      <div class="r-o-ssg">
        <table class="com-table">
          <tr>
            <th>期数</th>
            <th width="140">时间</th>
            <th colspan="3">平一</th>

            <th colspan="3">平二</th>

            <th colspan="3">平三</th>

            <th colspan="3">平四</th>

            <th colspan="3">特码</th>

            <th colspan="3">合值</th>

            <th>龙虎</th>
          </tr>
          <tr v-for="item in list" :key="item.Expect">
            <td>{{item.Expect}}</td>
            <td>{{item.Opentime|substrSome}}</td>
            <!-- item.codeArr -->
            <template v-for="(code,index) in item.codeArr">
              <td :key="code" class="td-ball">
                <p :class="'bgcball b'+(code>20?'max':code)">{{code}}</p>
              </td>
              <td :key="code+1" :class="fontColor[item.resultArr[index].ds]">
                {{item.resultArr[index].ds}}
              </td>
              <td :key="code+2" :class="fontColor[item.resultArr[index].dx]"> {{item.resultArr[index].dx}}</td>
            </template>

            <td :class="fontColor[n]" v-for="(n,index) in item.lutuResult" :key="index">{{n}}</td>

          </tr>
        </table>
        <div class="page">
          <!-- :page-sizes="[20,50,100,200]" -->
          <el-pagination @current-change="handleCurrentChange" :current-page="pages.pagenum" :page-size="pages.pagecount" layout="total, prev, pager, next, jumper" :total="totals">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import kjmixins from "../mixins/kjmixins";
export default {
  name: "kjbgc",
  props: [""],
  mixins: [kjmixins],
  data() {
    return {};
  },

  components: {},
  created() {},
  computed: {},

  methods: {},
};
</script>
<style  scoped>
.r-o-ssg .com-table tr td.ds_d,
.r-o-ssg .com-table tr td.dx_d {
  color: red;
}
.r-o-ssg .com-table tr td.ds_s,
.r-o-ssg .com-table tr td.dx_x {
  color: #00f;
}
li,
ul {
  list-style: none;
}
</style>